exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conheca-index-js": () => import("./../../../src/pages/conheca/index.js" /* webpackChunkName: "component---src-pages-conheca-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-contrate-index-js": () => import("./../../../src/pages/contrate/index.js" /* webpackChunkName: "component---src-pages-contrate-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proposito-index-js": () => import("./../../../src/pages/proposito/index.js" /* webpackChunkName: "component---src-pages-proposito-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

